//colors
$white-color: #fff;
$light-grey: #f3f3f3;
$url: "https://dua-admin.albcode.al/";

//spaces
$sidebar-padding: 2rem;
$sidebar-t-b-padding: 0.65rem;
$product-card-border-radius: 5px;

.App {
  overflow-x: hidden;
}

.long-description-format {
  white-space: pre-line;
}

.product-category img, .product-category figure:after {
  min-height: 250px;
  object-fit: contain;
  background-color: $white-color;
}

.menu .megamenu .submenu {
  display: flex;
  flex-direction: column;
  max-height: 450px;
  flex-wrap: wrap;
}

.section-title.categories-section-title {
  margin-bottom: 2.5rem;
}

.category-content {
  bottom: -15px;
}

.banner-white-text {
  color: $white-color;
}

.home-slide1 .banner-layer, .home-slide2 .banner-layer, .banner-white-text{
  text-shadow: black 0 0 10px;
}

.home-slider {
  h1, h2, h3, h4, h5, h6 {
    color: #fff !important;
  }

  h4 {
    font-size: 2.125em;
    font-weight: 500;
    opacity: 1;
  }
}

.invalid-feedback {
  display: inline-block;
  margin-bottom: 1.25rem;
}

.login-popup .form-footer .custom-control {
  margin: 0;
}

.post-media > .post-slider, .post-media > .lazy-load-image-background {
  position: initial;
}

.single {
  display: flex;
  flex-direction: column;
  .post-media, .post-body {
    display: block;
  }

  .post-title {
    white-space: normal;
    font-size: 2.5rem;
    line-height: 30px;
  }
}

.toolbox-item {
  .search {
    height: 34px;
  }
}

.brands-slider {
  figure {
    text-align: center;

    span {
      color: #222529;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      height: 100px;
      object-fit: contain;
      background-color: $white-color;
      margin-bottom: 1rem !important;
    }
  }

  figure:hover {
    span {
      color: $primary-color;
      text-decoration: none;
    }
  }
}

.sticky-navbar {
  justify-content: center;
}

.social-icon.social-youtube {
  background-color: #FF0000;
}

.whatsapp-icon {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 50px;
  z-index: 10;
  transition: all 0.2s ease-in-out;

  &.elevated {
    transform: translateY(-80%);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.solution-section-header {
  color: #515151;
}

.bullet-point-list {
  list-style: outside;
  padding-left: 15px;
}

.features-section .feature-box {
  margin-bottom: 2rem;
  padding: 2rem;
}

.business-register-main {
  position: relative;
}

.business-register-main:before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-image: url($url + "bundles/app/frontend/images/b2b-background.jpg");
  background-color: #000;
  background-size: cover;
  opacity: 0.7;
}

.business-register-container {
  margin-top: 0;
  .row {
    div {
      margin: 2rem;
      background-color: $white-color;
    }
  }
}

form {
  .relative-error {
    position: absolute;
    bottom: -20px;
    left: 0;
  }
}

.toolbox {
  gap: 2rem;
  .toolbox-left {
    flex-grow: 1;

    .toolbox-item {
      width: 100%;
    }
  }
}

.post-media {
  background-color: $white-color;
}

@media (max-width: 991px) {
  .mobile-sidebar {
    max-height: 100vh;
  }
}

@media (min-width: 576px) {
  .sidebar-product .widget.widget-product-categories {
    padding: 0;

    .widget-body {
      padding: 0;
    }
  }
}


@media (max-width: 479px) {
  .toolbox-item {
    .form-control {
      margin-right: 3px
    }
  }

  .footer {
    .col-sm-6 {
      &.widget-list {
        width: 50%;
      }
    }
  }

  .whatsapp-icon {
    &.elevated {
      transform: translateY(-130%);
    }
  }
}

.info-box.with-icon p:before {
  margin-top: 0;
  color: $primary-color;
}

.sidebar-shop .widget, .sidebar-product .widget {
  padding: $sidebar-padding 0 0;

  .widget-title {
    padding-left: $sidebar-padding;
    padding-right: $sidebar-padding;
  }

  .widget-body {
    .rc-tree.cat-list .rc-tree-treenode {
      padding: $sidebar-t-b-padding $sidebar-padding;
      margin: 0;
      border-bottom: 1px solid lightgray;
    }

    .rc-tree.cat-list .rc-tree-treenode:last-child {
      border-bottom: 1px solid transparent;
    }
  }
}

span.toggle {
  top: 5px;
  right: 5px;
}

.sidebar-product .widget.widget-product-categories, .sidebar-product .widget.widget-product-categories .widget-body {
  padding: 0;
}

.sidebar-product .widget {
  .widget-body {
    .rc-tree.cat-list .rc-tree-treenode {
      padding-top: $sidebar-t-b-padding;
      padding-bottom: $sidebar-t-b-padding;
      padding-left: $sidebar-padding;
    }
  }
  span.toggle {
    right: 0;
  }
}

.sidebar-shop .widget-title a:before, .sidebar-shop .widget-title a:after {
  right: -7px;
}

.skeleton-body.loaded.skel-shop-products .product-default {
  border: 1px solid lightgray;
  border-radius: $product-card-border-radius;
}

.media-with-lazy figure::before, .lazy-overlay, .media-with-lazy figure .lazy-overlay + .lazy-load-image-background img {
  border-top-left-radius: $product-card-border-radius;
  border-top-right-radius: $product-card-border-radius;
}

.owl-carousel {
  .animated {
    animation-duration: 500ms;
  }
}

.sidebar-shop .widget-title a:before, .sidebar-shop .widget-title a:after, .sidebar-product .widget-title a:after {
  content: none;
}